import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";

export default function SubscriptionTierWayfinder({ subscription_type }) {
  return (
    <Col md={4} className="mb-4">
      <Card className="text-center h-100">
        <Card.Body>
          <h2>🌋 Wayfinder Tribe Member</h2>
          <h3>$9.99/month</h3>
          <p>Go Big or Go Tropical! 🐚</p>
          <ul className="list-unstyled">
            <li>📸 <strong>100GB of storage</strong> for all your high-quality adventures</li>
            <li>📝 <strong>Unlimited posts</strong> (because why limit your vibes?)</li>
            <li>🌺 Full access to the Tiki Tribe community</li>
            <li>🌞 Only $9.99/month for full access to the Tiki Tribe experience!</li>
          </ul>
          {subscription_type === 'beachcomber' && (
          <Button variant="primary" size="lg" href="/checkout/wayfinder">
            Step Up as a Wayfinder!
          </Button>
          )}
          {subscription_type === 'islander' && (
            <Button variant="primary" size="lg" href="/checkout/wayfinder">
              Step Up as a Wayfinder!
            </Button>
          )}
          {subscription_type === 'wayfinder' && (
            <Button variant="secondary" size="lg" disabled>
              You’re Living the Wayfinder Adventure!
            </Button>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}
