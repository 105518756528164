import Spinner from "react-bootstrap/Spinner";
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useCallback } from "react";
import Body from "../components/Body";
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiProvider';
import Posts from "../components/Posts";

export default function MemberPage({ setShowHeader }) {
  const { Memberusername } = useParams(); // Get Memberusername from route params
  const { token } = useAuth();
  const api = useApi();
  const [posts, setPosts] = useState([]); // Initialize as an empty array
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Track if all posts have been loaded


  const fetchPosts = useCallback(async (lastTimestamp = null) => {
    setLoading(true);

    const url = '/tt_member_posts_get';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    if (lastTimestamp) {
      headers['x-timestamp'] = lastTimestamp;
    }
    const memberData = {
      membername: Memberusername,
    };

    const response = await api.post(url, memberData, { headers }, token);
    if (response.ok) {
      const fetchedPosts = response.body.data;
      if (fetchedPosts.length > 0) {
        setPosts(prevPosts => lastTimestamp ? [...prevPosts, ...fetchedPosts] : fetchedPosts);
        setOldestTimestamp(fetchedPosts[fetchedPosts.length - 1].Timestamp);
        setAllPostsLoaded(false); // New posts loaded, so we can still load more
      } else {
        setAllPostsLoaded(true); // No more posts available
      }
    } else {
      console.error('Failed to fetch posts:', response);
    }
    setLoading(false);
  }, [token, api, Memberusername]);

  useEffect(() => {
    fetchPosts(); // Load initial posts
  }, [fetchPosts]);

  return (
    <Body>
      {loading && posts.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" />
        </div>
      ) : posts.length === 0 ? (
        <p className="text-center">
          {Memberusername} hasn't posted anything yet. Encourage them to share their island adventures!
        </p>
      ) : (
        <>
          {posts && posts.map(post => (
            <Posts
              key={post.PostID}
              post={post}
              initialShowComments={false} // Set to false to initially hide comments
              setShowHeader={setShowHeader} // Pass setShowHeader to Posts
            />
          ))}
          {loading && (
            <div className="d-flex justify-content-center my-4">
              <Spinner animation="border" />
            </div>
          )}
          {!loading && !allPostsLoaded && (
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => fetchPosts(oldestTimestamp)}
                className="comment-toggle-button"
                variant="outline-primary"
                disabled={loading}
                style={{
                  marginBottom: '20px'
                }}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Previous Messages'
                )}
              </Button>
            </div>
          )}
          {allPostsLoaded && (
            <p className="text-center my-4">You've reached the end of the feed!</p>
          )}
        </>
      )}
    </Body>
  );
}
