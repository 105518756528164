import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";

export default function SubscriptionTierIslander({ subscription_type }) {
  return (
    <Col md={4} className="mb-4">
      <Card className="text-center h-100">
        <Card.Body>
          <h2>🏝️ Islander Tribe Member</h2>
          <h3>$2.99/month</h3>
          <p>Join the Island Spirit! 🌊</p>
          <ul className="list-unstyled">
            <li>📸 10GB of storage for your photos and videos</li>
            <li>📝 Post up to <strong>100 times a month</strong></li>
            <li>🌺 Full access to the Tiki Tribe community</li>
            <li>🌞 Just $2.99/month for all this island fun!</li>
          </ul>
          {subscription_type === 'beachcomber' && (
            <Button variant="primary" size="lg" href="/checkout/islander">
              Join the Islander Tribe
            </Button>
          )}
          {subscription_type === 'islander' && (
            <Button variant="secondary" size="lg" disabled>
              You’re Part of the Islander Tribe!
            </Button>
          )}
          {subscription_type === 'wayfinder' && (
            <Button variant="light" size="lg" href="/checkout/islander">
              Anchor Down with the Islanders
            </Button>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}
