import Container from 'react-bootstrap/Container';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';

import FlashProvider from './contexts/FlashProvider';
import ApiProvider from './contexts/ApiProvider';
import UserProvider from './contexts/UserProvider';
import {AuthProvider} from './contexts/AuthContext';
import { OnlineStatusProvider } from './contexts/OnlineStatusProvider';

import AccountPage from './pages/AccountPage';
import MediaGalleryPage from './pages/MediaGalleryPage';
import WelcomePage from './pages/WelcomePage';
import DownloadAppPage from './pages/DownloadAppPage';
import HomePage from './pages/HomePage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import MemberPage from './pages/MemberPage';
import UserPage from './pages/UserPage';
import FriendsPage from './pages/FriendsPage';
import FriendsSearchPage from './pages/FriendsSearchPage';
import ProfilePage from './pages/ProfilePage';
import TermsConditionsPage from './pages/TermsConditionsPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import theme from './theme';
import { CheckoutForm, Return } from './components/CheckoutForm'


export default function App() {
  const [showHeader, setShowHeader] = useState(true); // State to control header visibility
  return (
    <Container fluid className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
          <BrowserRouter>
            <FlashProvider>
              <AuthProvider>
                <ApiProvider>
                  <UserProvider>
                    {showHeader && <Header />} {/* Conditionally render the Header */}
                    <OnlineStatusProvider>
                      <Routes>
                        <Route path="/" element={
                          <PublicRoute><WelcomePage/></PublicRoute>}/>
                        <Route path="/download_app" element={
                          <PublicRoute><DownloadAppPage/></PublicRoute>}/>
                        <Route path="/privacypolicy" element={
                          <PublicRoute><PrivacyPolicyPage/></PublicRoute>}/>
                        <Route path="/termsandconditions" element={
                          <PublicRoute><TermsConditionsPage/></PublicRoute>
                        }/>
                        <Route path="*" element={
                          <PrivateRoute>
                            <Routes>
                              <Route path="/home" element={<HomePage setShowHeader={setShowHeader} />}/>
                              <Route path="/subscriptions" element={<SubscriptionsPage/>}/>
                              <Route path="/download" element={<DownloadAppPage/>}/>
                              <Route path="/friends" element={<FriendsPage/>}/>
                              <Route path="/friends_search" element={<FriendsSearchPage/>}/>
                              <Route path="/media_gallery" element={<MediaGalleryPage/>}/>
                              <Route path="/member/:Memberusername" element={<MemberPage setShowHeader={setShowHeader} />} />
                              <Route path="/my_posts" element={<UserPage setShowHeader={setShowHeader} />}/>
                              <Route path="/profile" element={<ProfilePage/>}/>
                              <Route path="/account" element={<AccountPage/>}/>
                              <Route path="/checkout/:membership_type" element={<CheckoutForm />} />
                              <Route path="/return" element={<Return />} />
                              <Route path="*" element={<Navigate to="/home"/>}/>
                            </Routes>
                          </PrivateRoute>
                        }/>
                      </Routes>
                    </OnlineStatusProvider>
                  </UserProvider>
                </ApiProvider>
              </AuthProvider>
            </FlashProvider>
          </BrowserRouter>
      </ThemeProvider>
    </Container>
  );
}
