import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useCallback } from "react";
import Body from "../components/Body";
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiProvider';
import Write from "../components/Write";
import Posts from "../components/Posts";


export default function HomePage({ setShowHeader }) {
  const { token, username } = useAuth();
  const api = useApi();
  const [posts, setPosts] = useState([]);
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pullDownLoading, setPullDownLoading] = useState(false); // Track loading for pull-to-refresh
  const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Track if all posts have been loaded
  const [pullDown, setPullDown] = useState(false); // Track if pull-down refresh is triggered

  const navigate = useNavigate();

  const handleInviteFriendClick = () => {
    navigate('/friends');
  };

  const showPost = (newPost) => {
    setPosts([newPost, ...posts]);
  };

  const updatePostInParent = (updatedPost) => {
    setPosts(posts.map(post => post.PostID === updatedPost.PostID ? updatedPost : post));
  };

  const removePostFromParent = (postID) => {
    setPosts(posts.filter(post => post.PostID !== postID));
  };

  const fetchPosts = useCallback(async (lastTimestamp = null, isPullDown = false) => {
    if (isPullDown) {
      setPullDownLoading(true); // Show pull-down spinner
    } else {
      setLoading(true); // Show main loading spinner
    }

    const url = '/tt_posts_get';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    if (lastTimestamp) {
      headers['x-timestamp'] = lastTimestamp;
    }

    const response = await api.get(url, null, { headers }, token);
    if (response.ok) {
      const fetchedPosts = response.body.data;
      if (fetchedPosts.length > 0) {
        setPosts(prevPosts => lastTimestamp ? [...prevPosts, ...fetchedPosts] : fetchedPosts);
        setOldestTimestamp(fetchedPosts[fetchedPosts.length - 1].Timestamp);
        setAllPostsLoaded(false); // New posts loaded, so we can still load more
      } else {
        setAllPostsLoaded(true); // No more posts available
      }
    } else {
      console.error('Failed to fetch posts:', response);
    }

    setLoading(false);
    setPullDownLoading(false);
  }, [token, api]);

  useEffect(() => {
    fetchPosts(); // Load initial posts
  }, [fetchPosts]);

  // Pull-to-refresh logic
  useEffect(() => {
    let startY = 0;

    const onTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      if (currentY - startY > 50) { // Adjust threshold for refresh
        setPullDown(true);
      }
    };

    const onTouchEnd = () => {
      if (pullDown) {
        fetchPosts(null, true); // Refresh posts when pulled down
      }
      setPullDown(false);
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [pullDown, fetchPosts]);

  return (
    <Body>
      {pullDownLoading && (
        <div className="d-flex justify-content-center my-3">
          <Spinner animation="border" size="sm" />
          <span className="ms-2">Refreshing...</span>
        </div>
      )}
      {loading && posts.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" />
        </div>
      ) : posts.length === 0 ? (
        <>
          <h2>Aloha, {username}!</h2>
          <p>Looks like your feed is a blank canvas! Time to add some splash by sharing your island vibes!</p>
          <ul>
            <li>🌺 Capture and treasure your best tropical pics and vids</li>
            <li>🌴 Share your tales of adventure and discovery</li>
            <li>🏝️ Bring a buddy on board and start sharing the good times together</li>
          </ul>
          <p>Tiki Tribe is all about keeping it chill while staying secure. No ads, just pure island-style connections!</p>
          <Write showPost={showPost} />
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="outline-primary" onClick={handleInviteFriendClick}>
              Invite a Friend
            </Button>
          </div>
        </>
      ) : (
        <>
          <Write showPost={showPost} />
          {posts && posts.map(post => (
            <Posts
              key={post.PostID}
              post={post}
              updatePostInParent={updatePostInParent}
              removePostFromParent={removePostFromParent}
              initialShowComments={true} // Set to false to initially hide comments
              setShowHeader={setShowHeader} // Pass setShowHeader to Posts
            />
          ))}
          {loading && (
            <div className="d-flex justify-content-center my-4">
              <Spinner animation="border" />
            </div>
          )}
          {!loading && !allPostsLoaded && (
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => fetchPosts(oldestTimestamp)}
                className="comment-toggle-button"
                disabled={loading}
                variant="outline-primary"
                style={{
                  marginBottom: '20px'
                }}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Previous Messages'
                )}
              </Button>
            </div>
          )}
          {allPostsLoaded && (
            <p className="text-center my-4">You've reached the end of the feed!</p>
          )}
        </>
      )}
    </Body>
  );
}
