import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useCallback } from "react";
import Body from "../components/Body";
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiProvider';
import Posts from "../components/Posts";
import { useOnlineStatus } from '../contexts/OnlineStatusProvider';

export default function UserPage({ setShowHeader }) {
  const { token } = useAuth();
  const api = useApi();
  const isOnline = useOnlineStatus(); // Check if the user is online
  const [posts, setPosts] = useState([]); // Initialize as an empty array
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Track if all posts have been loaded

  const updatePostInParent = (updatedPost) => {
    setPosts(posts.map(post => post.PostID === updatedPost.PostID ? updatedPost : post));
  };

  const removePostFromParent = (postID) => {
    setPosts(posts.filter(post => post.PostID !== postID));
  };

  const fetchPosts = useCallback(async (lastTimestamp = null) => {
    if (!isOnline) {
      console.warn("You are offline. Cannot fetch posts.");
      return;
    }

    setLoading(true);
    const url = '/tt_user_posts_get';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    if (lastTimestamp) {
      headers['x-timestamp'] = lastTimestamp;
    }

    try {
      const response = await api.get(url, null, { headers }, token);
      if (response.ok && response.body?.data) {
        const fetchedPosts = response.body.data;
        if (fetchedPosts.length > 0) {
          setPosts(prevPosts => lastTimestamp ? [...prevPosts, ...fetchedPosts] : fetchedPosts);
          setOldestTimestamp(fetchedPosts[fetchedPosts.length - 1].Timestamp);
          setAllPostsLoaded(false); // New posts loaded, so we can still load more
        } else {
          setAllPostsLoaded(true); // No more posts available
        }
      } else {
        console.error('Failed to fetch posts:', response);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setLoading(false);
  }, [token, api, isOnline]);

  useEffect(() => {
    fetchPosts(); // Load initial posts if online
  }, [fetchPosts]);

  return (
    <Body>
      {isOnline ? (
        loading && posts.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Spinner animation="border" />
          </div>
        ) : posts.length === 0 ? (
          <p className="text-center">
            Your island is still waiting for its first wave of stories!
          </p>
        ) : (
          <>
            {posts.map(post => (
              <Posts
                key={post.PostID}
                post={post}
                updatePostInParent={updatePostInParent}
                removePostFromParent={removePostFromParent}
                initialShowComments={false} // Set to false to initially hide comments
                setShowHeader={setShowHeader} // Pass setShowHeader to Posts
              />
            ))}
            {loading && (
              <div className="d-flex justify-content-center my-4">
                <Spinner animation="border" />
              </div>
            )}
            {!loading && !allPostsLoaded && (
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => fetchPosts(oldestTimestamp)}
                  className="comment-toggle-button"
                  variant="outline-primary"
                  disabled={loading}
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Previous Messages'
                  )}
                </Button>
              </div>
            )}
            {allPostsLoaded && (
              <p className="text-center my-4">You've reached the end of the feed!</p>
            )}
          </>
        )
      ) : (
        <div className="text-center my-4">My Posts are unavailable while offline.</div>
      )}
    </Body>
  );
}
