import Body from '../components/Body';
import { NavLink } from 'react-router-dom';
import Nav from "react-bootstrap/Nav";
import theme from "../theme";
import { useAuth } from '../contexts/AuthContext';
import React, { useEffect, useState } from "react";

export default function WelcomePage() {
  const { join } = useAuth();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  // Update screen size state when window resizes
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Body>
      <div>
        <h3 style={{ textAlign: 'right' }}>
          <Nav.Link
            href="/download_app"
            style={{  WebkitTextStroke: '0.5px #007bff', color: theme.colors.turquoise, fontSize: '1rem' }}
          >
            Download App
          </Nav.Link>
        </h3>


        {isLargeScreen ? (
          <div>
            <h1>Welcome to <strong>Tiki Tribe</strong></h1>
            <p>Where the sun is always shining, and your privacy is the ultimate treasure! 🌴 Here,
              you're not just part of any community; you're part of a tribe—a tribe that values your peace of mind above
              all else. You pay for the vibe, not with your data. That's right, your stories, snaps, and posts are
              shared only
              with the tribe members you choose. No ads lurking in the background, no sneaky data deals—just you and
              your
              crew enjoying the island life, uninterrupted.</p>

            <p>In <strong>Tiki Tribe</strong>, you’re the chief of your content. Everything you
              post is yours, and it stays that way. 'Tiki' isn’t just a nod to the tropical; it’s a promise of a space
              that’s as pure and unspoiled as a secluded island. This is your oasis, where you connect with friends on
              your terms, under the shade of privacy.</p>

            <p>Feel the freedom to express, share, and engage in a community where the only
              audience is the one you invite to the luau. Our subscription model means we’re here for you, not for
              advertisers. We keep the fire burning for you and your tribe, not for those who want to peek into your
              business.</p>

            <p>Set sail into a world where being social doesn’t mean giving up your secrets. From
              day one, your privacy has been our treasure to protect. This is social networking the way it was meant to
              be—safe, secure, and all yours.</p>

            <p>Because your privacy isn’t just a feature; it’s the island way of life. 🌺</p>
          </div>
        ) : (
          <div>
            <h4>Welcome to <strong>Tiki Tribe</strong></h4>
            <p style={{fontSize: '1rem', lineHeight: '1.5'}}>
              Where privacy is the ultimate treasure! 🌴 Join a community that values your peace of mind above all else.
            </p>

            <ul style={{paddingLeft: '1.5rem', lineHeight: '1.8', fontSize: '0.9rem'}}>
              <li><strong>Privacy First:</strong> Share stories only with those you choose, with no ads or data
                tracking.
              </li>
              <li><strong>You Own Your Content:</strong> Everything you post is yours, always.</li>
              <li><strong>No Ads, No Worries:</strong> We’re subscription-based, so we serve you, not advertisers.</li>
              <li><strong>Tribe on Your Terms:</strong> Connect in a space that’s safe, secure, and ad-free.</li>
            </ul>

            <p style={{fontSize: '1rem', marginTop: '1rem'}}>
              Embrace social networking the island way, where privacy and freedom reign.
            </p>
          </div>
        )}

        <Nav.Link
          onClick={join}
          style={{ WebkitTextStroke: '0.5px #007bff', color: theme.colors.turquoise, fontSize: '1.2rem', fontFamily: 'Bungee', marginTop: '1rem' }}
        >
          Join Tiki Tribe
        </Nav.Link>
        <Nav.Link as={NavLink} to="/termsandconditions" className="terms-link" style={{ fontSize: '0.8rem' }}>
          Terms and Conditions
        </Nav.Link>
      </div>
    </Body>
  );
}
